.shop-section{
    background-image: url('../assest/images/Fresh-and-Tropical-Fruits.jpg');
    display: flex;
    width: 100%; 
}
.shop-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    padding-bottom: 2%;
}
.shop-title{
    text-shadow: 1px 1px 50px #202020;
    font-size: 80px;
    margin-top: 2%;
    font-weight: 700;
    color: rgb(211, 177, 125);
    text-align: center;
    margin-bottom: 2%;
}
.shop-list{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 2%;
    background-color: rgba(230, 180, 165, 0.938);
    padding: 30px 20px;
    border-radius: 15px;
}
.list-item{
    width: 80%;
}
.list-item-image:hover{
    transform: scale(2.1);
    transition: all 0.5s ease-in-out;
   z-index: 10;
    transition: transform 0.5s ease-in-out;
}