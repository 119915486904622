.contact-section{
    background-image: url('../assest/images/2a8777fb7f10db2117007fb1e445831e.png');
    display: flex;
    width: 100%;
}
.contactus-card{
    background-image: linear-gradient(to right bottom, #ffffff00, #fdfbfb 50%, #fdfbfb 55%, #ffffff 75%, #ffffff 75%);
    width: 80%;
    height: 100%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 15px;
}
.contact-us-banner{
    background-image: url('../assest//images/Contact-us-banner-1.png');
    width: 50%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: contain;
}
.contactus-section{
    display: flex;
    flex-direction: column;
    height: 500px;
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 50%;
}
.contactus-title{
    font-size: 25px;
    font-weight: 500;
    margin: 2%;
    margin-bottom: 20px;
}
.contactus-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: #1a1717 3px solid;
    padding: 30px;
    border-radius: 15px;
    width: 80%;
    height: 300px;
    float: right;
}
.input-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.input{
    width: 80%;
    height: 20px;
    padding: 5px 8px;
    margin-top: 15px;
    border-width: 2px;
    border-radius: 5px;
    font-weight: 500;
}
.error-message{
    color: red;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.form-button{
    width: 100%;
    height: 150px;
    border-radius: 5px;
    border: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.5s ease-in-out;
    box-shadow: 1px 1px 6px #202020;
}
.form-button:hover{
    color: #ffffff;
    background-color: #511c96;
}
.modal-container{
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    left: 30%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    top: 50%;
    background-color: #ffffff;
    z-index: 50;
    height: 250px;
    border-radius: 15px;
    box-shadow: 1px 1px 6px #202020;
    border: #511c96 3px solid;
}
.modal-header{
    font-size: 25px;
    font-weight: 500;
    margin: 2%;
    margin-bottom: 20px;
}
.modal-title{
    font-size: 20px;
    font-weight: 500;
    margin: 2%;
    margin-bottom: 20px;
}
.modal-close{
    position: absolute;
    top: 0;
    right: 0;
    margin: 2%;
    margin-bottom: 20px;
    cursor: pointer;
}
.modal-body{
    font-size: 20px;
    font-weight: 500;
    margin: 2%;
    margin-bottom: 20px;
}
.modal-message{
    font-size: 20px;
    font-weight: 500;
    margin: 2%;
    margin-bottom: 20px;
}