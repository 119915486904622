.nav-bar{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left:auto;
    padding-right: auto;
    z-index: 100;

}
.left-Nav{
   width: 40%;
}
.logo-banner{
    align-items: center;
    color: #000;
    font-family: League Spartan;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.right-nav{
    width: 70%;
}
.nav-links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.nav-link{
    list-style:none;
    color: #000;
    font-family: League Spartan;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 30px;
    cursor: pointer;
    border: 4px solid #00000000;
    border-radius: 90px;
    transition: all 0.30s ease-in;
}
.nav-link:hover{
    border: 4px solid #000;
    border-radius: 90px;
}
.nav-link-after{
    border: 4px solid #000;
    border-radius: 90px;
}
a{
    text-decoration: none;
}