.main-container{
    position: relative;
}
.navigetion-bar{
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
}
.hero-section{
    position: relative;
    z-index: 2;
    top: 0;
}
.hero-wrapper{
    background: #FFA89E;
    width: 99.1vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
}
.hero-item-name{
    z-index: 0;
    color: #FFF;
    text-align: center;
    font-family: League Spartan;
    font-size: 500px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 8%;
}
.hero-item-fruit{ 
    top: 0;
    left: 0;
    position: absolute;
    z-index: 3;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    filter: opacity(0.99);
}

.hero-item-can-wrap{
    display: flex;  
    justify-content: center; 
    position: absolute;
    top:10%;
    width: 100%;
    align-items: center;
    height: 80%;
    z-index: 3;
    justify-content: center;
    
}
.hero-item-can{
    z-index: 4;
    width: 14%;
    height: 90%;
    position: relative;
    top: 0;
}
.hero-button{
    width: 100%;
    height: 100%;
    z-index: 6;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
}
.hero-left-button{
    background-color: rgba(153, 205, 50, 0);
    width: 40%;
    height: 100%;
    border: 0;
}
.hero-right-button{
    background-color: rgba(153, 205, 50, 0);
    width: 60%;
    height: 100%;
    border: 0;
}
.item-section{
    background-color: #74bee1;
    width: 100%;
    height: 100vh; 
    overflow-x: hidden;
    padding-bottom: 100px;
    overflow: hidden;
    display: flex;
    padding-bottom: 10%;
    flex-direction: row;
}
.item-left-section{
    width: 50%;
    height: 100%;
}
.item-right-section{
    width: 70%; 
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    padding: 0px 3%;
    align-items: center;
    justify-content: space-between;
    background-image: url('../assest//images//pngimg.com\ -\ water_PNG50143.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.item-card{
    padding: 3%;
    margin-left: 2%;
    margin-top: 10%;
    box-shadow: 1px 1px 6px #202020;
    border-radius: 8px;
    margin-bottom: 5%;
    background-color: #ffffff;
}
.card-header{
    font-size: 30px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
    color: #1d6439;
    display: flex;
    flex-direction: row;  
    margin-bottom: 15px; 
}
.line{
    width: 60%;
    height: 3px;
    background-color: #1d6439;
}
.card-body{
    margin-left: 2%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}
.card-title{
    text-align: start;
    font-size: 28px;
    font-weight: 500;
    margin-top: 20px;
}
.card-description{ 
    text-align: start;
    font-size: 25px;
}
.card-footer{
}
.card-image{
    width: 55%;
    height: 50%;
}
.right-down-banner{
    width: 60%;
    border-radius:  15px 15px 15px 15px;
    box-shadow: 1px 1px 6px #202020;
    height: 40%;
    margin: 1%;
}
.shopping-section{
    background-color: #329c52;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
}
.list-wrapper{
    background-color: #329c52;
    width: 100%;
    height: 100vh; 
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
}
.list{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.list-item{
    width: 30%;
    height: 650px;
    padding: 1%;
    border-radius: 15px;
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
}
.list-item-image-wrap{
    width: 100%;
}
.list-item-image{
    width: 20%;
    height: 100%;
}
.list-item-description-wrapper{
    height: 65%;
    padding: 1%;
}
.list-item-name{
    font-size: 32px;
    color: #202020;
    font-weight: 500;
}
.list-item-price{
    font-size: 25px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    width: 60%;
    border-radius: 5px;
    background-color: #c2938e;
}
.red{
    background-color: #FFA89E;
    
}
.green{
    background-color: #B2E174;
}
.purpul{
    background-color: #CFAAFF;
}
.list-item-description{
    font-size: 20px;
    text-align: start;
    padding: 20px;
    margin-bottom: 50px;
    height: 140px;
}
.list-item-button{
    width: 60%;
    height: 13%;
    border-radius: 5px;
    border: 0px;
    position: relative;
    bottom: 0;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.5s ease-in-out;
    box-shadow: 1px 1px 6px #202020;
}
.list-item-button:hover{
    color: #ffffff;
    background-color: #e06f6b;
}